import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Fitness App Template | Fitness App Design Template | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/fitness-mobile-app/"
    metaDescription="Ready to see some serious gains? Our new fitness app design template for mobile is here to transform the way you design. Sign up today."
    description="
    h2:Introducing BeFit, our fitness app design template for mobile
    <br/>
    Ready to flex your design muscles? Want to be the quickest <a:https://uizard.io/prototyping/>UX/UI prototyper</a> on the track? Our new sports fitness UI template is here to transform you from average Joe to the elite product designer you were born to be. BeFit comes fully kitted out with all the must-have features for any self-respecting fitness app design. Simply sign up to Uizard Pro and streamline your design flow today.
    <br/>
    h3:A UI template for a stunning fitness mobile app
    <br/>
    Here at Uizard, we pride ourselves on crafting the best design solutions for product designers, regardless of skill or experience. BeFit boasts a stunning, sports aesthetic that is sure to tick your boxes on style and substance. Our <a:https://uizard.io/templates/>UI templates</a> are fully customizable though, meaning you have unlimited flexibility to add screens, change coloring, and even change the entire theme of the app design.
    <br/>
    h3:There is no 'I' in team…
    <br/>
    Working with a team? We have made collaboration easier than ever before with our smart sharing features, meaning you can share your project with stakeholders with the click of a button and collaborate in real time. If the team wins, we all do.
    "
    pages={[
      "A detailed onboarding screen for the user",
      "A home dashboard with various workouts",
      "A search directory",
      "A workout overview and workout-in-progress preview",
      "A personal profile with a leaderboard and user's achievements",
      "A 'Favorites' screen for saved workouts",
    ]}
    projectCode="XXJO9A7WRKTEyYZdpVBW"
    img1={data.image1.childImageSharp}
    img1alt="mobile fitness app design overview"
    img2={data.image2.childImageSharp}
    img2alt="mobile fitness app design favourite workouts screen"
    img3={data.image3.childImageSharp}
    img3alt="mobile fitness app design hiit cardio screen"
    img4={data.image4.childImageSharp}
    img4alt="mobile fitness app design calculator screen"
    img5={data.image5.childImageSharp}
    img5alt="mobile fitness app design summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/fitness-mobile-app/workout-app-cover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/fitness-mobile-app/workout-app-favorites.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/fitness-mobile-app/workout-app-hiit-cardio.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/fitness-mobile-app/workout-app-calculator.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/fitness-mobile-app/workout-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
